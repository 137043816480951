import React from 'react'
import './project.css'

const Project = ({projectName,desc,year,role,completeStatus,url,img}) => {
  return (
    <div className='app__project app__wrapper' data-aos="fade-up" data-aos-duration="1500">
      <img src={img} alt='project image' />
      <div className='app__project__desc'>
        <h1 className='head__manrope'>{projectName}</h1>
        <div className='app__projects__desc-p'>
          <p className='p__manrope'>{desc}</p>
        </div>
        <div className='app__project__desc-info'>
          <h1 className='subhead__manrope'>PROJECT INFO</h1>
          <hr />
          <div className='app__project__desc-info-year app__wrapper'>
            <p className='p__manrope'>Year :</p>
            <p className='p__manrope'>{year}</p>
          </div>
          <hr />
          <div className='app__project__desc-info-role app__wrapper'>
            <p className='p__manrope'>Role :</p>
            <p className='p__manrope'>{role}</p>
          </div>
          <hr />
          <div className='app__project__desc-info-link p__manrope'>
            {completeStatus ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                Visit Website
              </a>
            ) : null}
          </div>
        </div>       
      </div>
    </div>
  )
}

export default Project
